



































































































import { Component, Vue } from 'vue-property-decorator'
import { apiFinanceDataCenter, apiExport } from '@/api/finance/finance'
import downloadExcel from 'vue-json-excel'

@Component({
    components: {
        downloadExcel,
    }
})
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData = {}
    tableData = []
    export_loading = false
    loading = false
    // 顶部查询表单
    form = {
        start_time: '',
        end_time: ''
    }
    DetailsForm: any = [
        //写死，后续可通过接口进行传值
     
    ]
    // 头部文字
    header_text:any = ''
    json_fields = {
        "配送产品": "goods_name",    //常规字段
        "数量":"every_num",
        "应销售金额":"total_price",
        "实收金额（扣除优惠）":"sell_price",
        "成本金额":"cost_price",
        "配送费":"total_delivery_fee",
        "佣金":"total_earnings",
    }
    /** E Data **/


    /** S Methods **/
    // 获取财务概况
    financeDataCenter() {
        if(this.form.start_time) {
            this.header_text = '配送财务明细表（' + this.form.start_time + '至' + this.form.end_time + '）'
        }else {
            this.header_text = '配送财务明细表'
        }
        this.loading = true
        apiFinanceDataCenter({
            ...this.form
        })
            .then(res => {
                this.loading = false
                this.ProfileData = res
            })
            .catch(res => {
                this.loading = false
            })
    }
    // 获取数据
    getData() {
        this.financeDataCenter()
        this.getExportData()
    }
    // 获取导出数据
    getExportData() {
        this.export_loading = true

        apiExport({
            ...this.form
        }).then(res => {
            this.export_loading = false
            console.log("res", res)
            this.DetailsForm = res
            let totle_obj:any = {
                goods_name: '合计',
                every_num: 0,
                total_price: 0,
                sell_price: 0,
                cost_price: 0,
                total_delivery_fee: 0,
                total_earnings: 0

            }
            res.forEach((item:any) => {
                totle_obj.every_num+= Number(item.every_num)
                totle_obj.total_price+= Number(item.total_price)
                totle_obj.sell_price+= Number(item.sell_price)
                totle_obj.cost_price+= Number(item.cost_price)
                totle_obj.total_delivery_fee+= Number(item.total_delivery_fee)
                totle_obj.total_earnings+= Number(item.total_earnings)

                console.log("item", item)
            })
            this.DetailsForm.push({ ...totle_obj})
        }).catch(err => {
            this.export_loading = false
        })
    }
    // 时间分割
    splitTime() {
        if (this.tableData != null) {
            this.form.start_time = this.tableData[0]
            this.form.end_time = this.tableData[1]
        }
    }
    // 重置
    onReset() {
        this.tableData = []
        this.form.start_time = ''
        this.form.end_time = ''

        this.financeDataCenter()
        this.getExportData()
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.financeDataCenter()
        this.getExportData()
        // let arr = [
        //     { name: "小王", shuxue: 20, yuwen: 30 },
        //     { name: "小王", shuxue: 20, yuwen: 30 },
        //     { name: "小李", shuxue: 20, yuwen: 30 },
        //     { name: "小李", shuxue: 20, yuwen: 30 }
        // ]
        // let name_arr = [ '小王', '小李', '小巩' ]
        // let result = []
        // name_arr.forEach(item => {
        //     let shuxue_total = 0
        //     let yuwen_total = 0
        //     let obj1 = {
        //         shuxue_total: 0,
        //         yuwen_total: 0,
        //     }

        //     arr.forEach(item2 => {
        //         if(item2.name == item) {

        //         }
        //     })
        // })
    }
    /** E Life Cycle **/
}
